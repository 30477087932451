/*common*/

.glass {
    /*glass style effect???*/
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
}

.basic_container_text {
    height: 100%;
    display: flex;
    align-items: center;
    margin: auto;
    justify-content: center;
    font-family: "SF Pro Display Medium", sans-serif;
}

.full_height_container_text {
    height: 100%;
    color: white;
    font-family: "SF Pro Display Bold", sans-serif;
}

.background {
    background: rgba(18, 16, 38, 255);
}


.second_background {
    /*think about second background colour*/
    background: rgb(86, 41, 169, 255);
    /*background-image: linear-gradient(to bottom, #121026, #1b1a46, #2a2167, #3d2788, #5629a9);*/
}

.basic_container_wrapper {
    width: 100%;
    height: 90vh;
}

.basic_container_wrapper_double {
    height: 164.5vh;
}

.basic_container_wrapper_about {
    width: 100%;
    height: 60vh;
}

.main_logo {
    height: 4vh;
    width: 6.5vh;
    margin-right: 1vw;
    margin-left: 0.5vw;
}

.tg_style_icon {
    height: 4vh;
    width: 4vh;
    margin-right: 2vw;
}

.basic_container {
    width: 85%;
    height: 100%;
    display: flex;
    margin: auto;
}

.basic_container_vertical {
    display: flex;
    flex-direction: column;
}

.basic_container_centered {
    justify-content: center;
}

.extra_margin_top_10 {
    margin-top: 10vh;
}

.extra_margin_bottom_10 {
    margin-bottom: 10vh;
}

.spec_container_height {
    height: 87vh;
}

.no_margin_bottom {
    margin-bottom: 0 !important;
}

.inner_padding_top_1 {
    padding-top: 1vw;
}

.header1 {
    font-family: "SF Pro Display Bold", sans-serif;
    font-size: 3vw;
    color: white;
}

.header2 {
    font-size: 2vw !important;
}

.paragraph1 {
    font-size: 1vw;
}

.landscape_only {
    position: absolute;
    visibility: visible;
}

.portrait_only {
    position: absolute;
    visibility: hidden;
}


/*landing container*/

.land_container_height {
    height: 90vh;
    margin-bottom: 5vh;
}

.land_container_text > h1 {
    line-height: 3.2vw;
}

.land_container_text > div > h2 {
    color: white;
    margin-bottom: 4vh;
}

.land_contained_picture_middle {
    height: 100%;
    width: 100%;
    flex: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 4vw;
    margin-right: 4vw;
}

.land_contained_picture_middle > img {
    width: 37vw;
    height: 37vw;
}

.land_container_text > div > ul {
    margin-bottom: 3.5vh;
}

.land_container_text > div > ul > li {
    color: lightgray;
    line-height: 1.25vw;
    margin-bottom: 2vh;
}

.land_container_text_normal {
    color: lightgray;
    font-size: 1vw;
}

.land_container_text_spec {
    color: white;
    font-size: 1.1vw;
}

/*todo button color*/
.land_container_button {
    --bs-btn-active-border-color: rgb(173, 0, 174) !important;
    --bs-btn-hover-bg: rgb(173, 0, 174) !important;
    --bs-btn-hover-border-color: rgb(173, 0, 174) !important;
    --bs-btn-color: rgb(173, 0, 174) !important;
    --bs-btn-border-color: rgb(173, 0, 174) !important;
    font-size: 1.2vw !important;
}

.land_container_text_flex_up {
    flex: 2;
}

.land_container_flex_down {
    flex: 2;
}

/*advantages container*/

.advantages_container_height {
    height: 64vh;
}

.full_height_container_text > div > h3 {
    margin-left: 4vw;
    margin-bottom: 8vh;
    padding-top: 8vh;
}

.advantages_equal_images_container {
    margin-right: 5%;
    margin-left: 5%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: minmax(20vh, auto);
    grid-gap: 5vh;
    height: 100%;
    justify-content: center;
}

.advantages_equal_images_container > div {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.advantages_equal_images_container > div > p {
    font-size: 0.95vw;
    text-align: left;
    margin-top: 3vh;
    color: lightgray;
}

.advantages_equal_images {
    height: 8vw;
    width: 8vw;
    filter: invert(7%) sepia(17%) saturate(2152%) hue-rotate(206deg) brightness(95%) contrast(100%);
}

/*about container*/

.about_container_picture_left {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.about_container_picture_left > img {
    max-width: 65%;
    max-height: 65%;
}

.about_container_text > div > h3 {
    color: white;
    font-size: 1.6vw;
    margin-bottom: 3vh;
}

.about_container_text > div > ul > li {
    font-size: 0.9vw;
    color: white;
    line-height: 3vh;
    margin-bottom: 2vh;
}

.about_container_text > div > ul > li > p {
    font-size: 0.9vw;
    color: lightgrey;
}

.about_container_text > div > p {
    font-size: 1.1vw;
    color: lightgray;
}

.about_container_flex_up {
    flex: 3;
}

.about_container_flex_down {
    flex: 2;
}

/*card container*/

.basic_container_centered_cards {
    justify-content: center;
}

.equal_images_container_card {
    margin-right: 5%;
    margin-left: 5%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: minmax(40vh, auto);
    grid-gap: 5vh;
    justify-content: center;
}

.equal_images_container_card > div {
    flex: 1;
    text-align: center;
}

.equal_images_container_card > div > p {
    font-size: 1vw;
    text-align: left;
    margin-top: 0;
    margin-bottom: 1vh;
}

.equal_images_cards {
    height: 25vh;
    width: 20vw;
    margin: 3vh;
}

.cards_info {
    height: 65vh;
    background: rgba(18, 16, 34, 0.7);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    border-radius: 15px;
}

.cards_info > h6 {
    font-size: 1.3vw;
    margin-top: 4.5vh;
    margin-left: 3vw;
    text-align: left;
}

.cards_info > p {
    font-size: 1.1vw !important;
    margin-left: 3vw;
    margin-right: 3vw;
}

.cards_info > ul {
    margin-right: 1vw;
    margin-left: 1vw;
    line-height: 4vh;
}

.cards_info > ul > li {
    font-size: 0.8vw;
    color: lightgrey;
    text-align: left;
}


.cards_info > ul > li > p {
    margin-bottom: 0;
    line-height: 1.37vw;
}

/*how to container*/

.how_to_container_text {
    color: white;
    font-family: "SF Pro Display Bold", sans-serif;
}

.how_to_container_text > div > h3 {
    margin-left: 5vw;
    margin-right: 5vw;
    margin-bottom: 10vh;
    padding-top: 12vh;
}

.how_to_equals_container {
    margin-right: 5%;
    margin-left: 5%;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 3%;
    justify-content: center;
    align-items: center;
}

.how_to_balloons_connector {
    position: absolute;
    margin-top: 9.8vh;
    left: 14%;
    right: 0;
    width: 72%;
    height: 3px;
    background-color: rgb(86, 41, 169, 255);;
}

.how_to_equals_title {
    margin-top: 2vh;
    height: 5vh;
}

.how_to_equals_container > div {
    height: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
}

.how_to_equals_container > div > h3 {
    margin: 0;
    font-size: 1.2vw;
    text-align: center;
}


.how_to_equals_container > div > p {
    font-size: 0.85vw;
    text-align: center;
    color: lightgray;
}

.how_to_balloon {
    position: relative;
    height: 6vh;
    width: 6vh;
    background-color: rgb(86, 41, 169, 255);
    border-radius: 50%;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    line-height: 6vh;
    margin: 7vh;
    align-self: center;
}

.how_to_balloon > p {
    font-size: 0.85vw;
    margin: 0;
}

.how_to_container_portrait {
    display: flex;
}

.how_to_container_portrait_balloons_zone {
    flex: 2;
}

.how_to_container_portrait_text_zone {
    flex: 4;
}

.how_to_container_portrait_columns {
    height: 100%;
    display: grid;
    grid-template-rows: repeat(5, 1fr);
    justify-content: center;
    align-items: center;
    grid-gap: 2%;
}

.how_to_container_portrait_text {
    margin-right: 3vw;
    height: 25vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.how_to_container_portrait_balloons_wrapper {
    height: 25vh;
    display: flex;
    align-content: center;
}

.how_to_container_portrait_text > p {
    font-size: 3vw;
    color: lightgray;
    text-align: center;
}

.how_to_container_portrait_text > h3 {
    font-size: 4.5vw;
    text-align: center;
    margin-bottom: 2vh;
}

.how_to_balloons_portrait_connector {
    position: absolute;
    margin-top: 10vh;
    left: 16.35%;
    right: 0;
    width: 3px;
    height: 84%;
    background-color: rgb(86, 41, 169, 255);;
}

/*reviews container*/

.reviews_container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.reviews_carousel_wrapper {
    width: 40%;
}

.reviews_carousel_image_wrapper {
    display: flex;
    justify-content: center;
}

.reviews_carousel_image {
    height: 65vh;
}

.reviews_container > h3 {
    margin: 0 3vw 5vh !important;
    padding: 0 !important;
}

/*footer container*/

.footer {
    width: 100%;
    /*not really sure about footer height btw*/
    height: 12vh;
    border-top: 1px solid gray;
}

.footer_text_wrapper {
    height: 100%;
    margin-left: 7vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.footer_text_wrapper > p {
    color: lightgray;
    margin: 0;
    font-family: "SF Pro Display Bold", sans-serif;
}

/*media*/
@media screen and (max-width: 1500px) {
    .equal_images_container_card {
        margin-right: 5%;
        margin-left: 5%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-auto-rows: minmax(40vh, auto);
        grid-gap: 5vh;
        justify-content: center;
    }

    .cards_info {
        height: 65vh;
    }

    .basic_container_wrapper_double {
        height: 235vh;
    }

    .advantages_equal_images_container > div > p {
        font-size: 1.25vw;
        text-align: center;
    }

    .advantages_equal_images_container {
        grid-template-columns: repeat(2, 1fr);
    }

    .full_advantages_container_height {
        height: 100vh;
    }
}

@media screen and (max-width: 800px) {
}

@media screen and (max-width: 500px) {
    /*land container*/

    .basic_container {
        flex-direction: column;
    }

    .land_contained_picture_middle {
        flex: 2;
        margin-left: 0;
        margin-right: 0;
    }

    .land_container_text_flex_up {
        flex: 1;
        height: 50%;
    }

    .land_container_flex_down {
        flex: 2;
    }

    .land_contained_picture_middle > img {
        width: 40vh;
        height: 40vh;
    }

    .land_container_text_normal {
        font-size: 3vw;
    }

    .land_container_text_spec {
        font-size: 4vw;
    }

    .header1 {
        font-family: "SF Pro Display Bold", sans-serif;
        font-size: 6vw;
    }

    .header2 {
        font-size: 5vw !important;
        text-align: center;
        margin-top: 2vh;
    }

    .paragraph1 {
        font-size: 2vw;
    }

    .land_container_text > h1 {
        line-height: 6vw;
        text-align: center;
    }

    .land_container_text > div > h2 {
        margin-bottom: 2vh;
    }

    .land_container_button {
        width: 90%;
        margin-left: 5%;
        margin-right: 5%;
        margin-bottom: 1vh;
        font-size: 4vw !important;
    }

    .land_container_height {
        margin-bottom: 3vh;
    }

    .land_container_text > div > ul > li {
        font-size: 3vw;
    }

    /*advantages_container*/
    .full_height_container_text > div > h3 {
        padding-top: 2vh;
        margin-bottom: 4vh;
    }

    .advantages_equal_images_container {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 3vh;
        grid-auto-rows: minmax(15vh, auto);
    }

    .advantages_equal_images {
        height: 30vw;
        width: 30vw;
    }

    .advantages_container_height {
        height: 80vh;
    }

    .advantages_equal_images_container > div > p {
        font-size: 2.5vw;
        text-align: center;
        margin-top: 2vh;
    }

    .full_advantages_container_height {
        height: 98.5vh;
    }

    /*how to container*/
    .how_to_equals_container {
        grid-template-columns: 1fr;
    }

    .about_container_flex_up {
        flex: 1;
    }

    .about_container_picture_left {
        margin-top: 7vh;
    }

    .about_container_picture_left > img {
        max-width: 100%;
        max-height: 100%;
    }

    .about_container_text > div > h3 {
        font-size: 5vw;
        margin-bottom: 3vh;
        text-align: center;
    }

    .about_container_text > div > ul > li {
        font-size: 3vw;
        line-height: 2vh;
        margin-bottom: 1.5vh;
    }

    .about_container_text > div > ul > li > p {
        font-size: 3vw;
    }

    .about_container_text > div > p {
        font-size: 1.1vw;
        color: lightgray;
    }

    .about_container_flex_down {
        align-items: start;
        margin-top: 5vh;
    }

    /*cards container*/
    .margin_h3 {
        margin-top: 2vh;
        margin-bottom: 1vh;
        text-align: center;
    }

    .equal_images_container_card {
        grid-template-columns: repeat(1, 1fr);
        grid-auto-rows: minmax(20vh, auto);
        grid-gap: 3vh;
    }

    .cards_info {
        height: 52.5vh;
    }

    .equal_images_cards {
        height: 20vh;
        width: 60vw;
        margin: 0;
    }

    .cards_info > h6 {
        font-size: 4vw;
        margin-top: 2.5vh;
        margin-bottom: 2.5vh;
        margin-left: 5vw;
        text-align: left;
    }

    .cards_info > p {
        font-size: 3vw !important;
        margin-left: 3vw;
        margin-right: 3vw;
        padding-top: 2.5vh;
    }

    .cards_info > ul {
        line-height: 3.5vh;
    }

    .cards_info > ul > li {
        font-size: 2.8vw;
    }

    .cards_info > ul > li > p {
        line-height: 3vw;
    }

    .basic_container_wrapper_double {
        height: 349.2vh;
    }

    /*how to container*/

    .can_be_portrait {
        height: 147vh;
    }

    .landscape_only {
        visibility: hidden;
    }

    .portrait_only {
        visibility: visible;
    }

    .how_to_container_text > div > h3 {
        margin-bottom: 0;
        margin-left: 5vw !important;
        margin-right: 5vw !important;
        padding-top: 4vh;
        font-size: 3vh;
    }

    .how_to_balloon {
        height: 6vh;
        width: 6vh;
        line-height: 6vh;
        margin: 0;
    }

    .how_to_balloon > p {
        font-size: 4vw;
    }

    /*reviews container*/
    .reviews_carousel_wrapper {
        width: 95%;
    }

    .reviews_carousel_image {
        height: 65vh;
    }

    .reviews_container > h3 {
        font-size: 3vh;
        margin-bottom: 6.5vh !important;
    }
}
